import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { GlobalAppSettingsServiceService } from '../global-app-settings-service.service';
//import { Container, Main, ISourceOptions, ClickMode } from 'ng-particles';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

    private countdownDate = new Date(Date.UTC(2021, 8, 24, 16, 0, 0)); //utc
    private liveMessage = "Live Now!";
    public countdown: string = "";

    constructor(public appSettings: GlobalAppSettingsServiceService, private meta: Meta) {
        this.meta.updateTag({ name: 'robots', content: 'index, follow' }, "name='robots'");
    }

    ngOnInit() {
        setInterval(() => {
            this.updateCountdown();
          }, 1000);
    }

    updateCountdown() {

        var now = new Date().getTime();
        var distance = this.countdownDate.getTime() - now;

        if (distance < 0) {
            if (this.countdown !== this.liveMessage) {
                this.countdown = this.liveMessage;
            }
        } else {
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            this.countdown = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
        }
    }


    // options: ISourceOptions = {
    //     // fullScreen: {
    //     //     enable: true,
    //     // },
    //     fpsLimit: 40,
    //     particles: {
    //         number: {
    //             value: 80,
    //             density: {
    //                 enable: true,
    //                 area: 800,
    //             },
    //         },
    //         color: {
    //             value: ["#fdcf58", "#757676", "#f27d0c", "#800909", "#f07f13"],
    //         },
    //         opacity: {
    //             value: 0.5,
    //             random: true,
    //         },
    //         size: {
    //             value: 3,
    //             random: true,
    //         },
    //         move: {
    //             enable: true,
    //             speed: 6,
    //             random: false,
    //         },
    //     },
    //     interactivity: {
    //         events: {
    //             onClick: {
    //                 enable: true,
    //                 mode: ClickMode.push,
    //             },
    //             resize: true,
    //         },
    //     },
    //     background: {
    //         //image: "radial-gradient(#4a0000, #000)",
    //         color: "#000"
    //     },
    // };

    // particlesLoaded(container: Container): void {
    //     //console.log(container);
    // }

    // particlesInit(main: Main): void {
    //     // console.log(main);
    // }

}
