import { Injectable } from '@angular/core';
declare const GTagOptIn: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsServiceService {
  public readonly analyticsId: string = "G-XMNKB85ZTL";

  constructor() { }

  enableGoogleAnalytics() {
    GTagOptIn.register(this.analyticsId);
    GTagOptIn.optIn();
  }

  disableGoogleAnalytics() {
    GTagOptIn.register(this.analyticsId);
    GTagOptIn.optOut();
    this.deleteAllCookies();
  }

  private deleteAllCookies() {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    document.cookie = "cookieconsent_status=deny";
  }



}
