<div class="list-group my-5 mx-5">
    <a href="#" class="list-group-item list-group-item-action flex-column align-items-start active">
        <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">Links</h5>
            <small>24.09.2021</small>
        </div>
        <p class="mx-4 mt-4">
            <a class="text-white" href="https://trello.com/b/6dEZrRJD/release-roadmap" target="_blank">
                Trello - Public Roadmap
                </a>
        </p>
        <p class="mx-4">
            <a class="text-white" href="https://steamcommunity.com/app/1723730/discussions/" target="_blank">
                Steam Discussions
                </a>
        </p>
        <small></small>
    </a>
    <h5 class="bg-light"><a class="text-white" routerLink="/home" class="pb-5">&laquo; Back to the main page</a></h5>
</div>