<section class="bg-black text-center">
    <div class="container-fluid">
        <!-- <div class="row">
            <ul class="social_footer_ul p-2">
                <li><a href="{{appSettings.discordLink}}"><i class="fa fa-discord-alt"></i></a></li>
                <li><a href="{{appSettings.twitterLink}}"><i class="fa fa-twitter"></i></a></li>
                <li><a href="{{appSettings.steamLinkMeVsYou}}"><i class="fa fa-steam"></i></a></li>
            </ul>
        </div> -->
        <div class="row text-white pb-4">
            <div class="col-xs-6 col-sm-6 text-center">
                <span class="copyright">
                    Copyright &copy; {{currentYear}} <a href="{{appSettings.homepage}}"
                        style="color: white; font-weight: bold;">{{appSettings.fullCompanyname}}</a>
                        &nbsp;<a href="{{appSettings.twitterLink}}" style="color: white; font-weight: bold;" target="_blank"><i class="fa fa-twitter"></i></a>
                </span>
            </div>
            <div class="col-xs-6 col-sm-6 text-center">
                <a routerLink="/legal-terms" routerLinkActive="active" class="text-white">Legal Notice</a>
                <a routerLink="/legal-privacy" routerLinkActive="active" class="text-white ml-5">Privacy Policy</a>
            </div>
        </div>
    </div>
</section>