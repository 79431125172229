<div class="container">
    <div class="bg-light my-4 p-4">
        <h5><a routerLink="/home" routerLinkActive="active" class="pb-5">&laquo; Back to the main page</a></h5>
        <h1>Participation</h1>
        <ol>
            <li>Be fair and read the full terms and conditions below</li>
            <li>Record a video (e.g. OBS, Streamlabs OBS, ...) while you try to complete the competition (Quality:
                minimum 720p, 30fps). The final video has to include the whole attempt (start to end screen).</li>
            <li>Upload the video (e.g. youtube or any ad-free file host)</li>
            <li>Announce the completion or participation on
                <ul>
                    <li>Twitter (tag it with #mevsyoucompetition) or</li>
                    <li>Discord (Official Amped Horizon Discord Channel → #mevsyou-competition)</li>
                </ul>
                Include the link to the video and id/name of the competition (timestamp of the attempt if there
                are multiple to see in the video). If the competition is score-based, please also include your score.
                <h5>Double-check that the necessary information is included (if something is missing, the entry will be
                    invalid)</h5>
            </li>
            <li>Ensure that we can contact you via Twitter or Discord in case you've won the competition</li>
        </ol>
        In case the competition is about earning an achievement: <p class="text-danger">The winner will be determined
            based on when the announcement was made on Twitter or Discord (not the actual achievement date)!</p>
        <hr>
        <h1>V1 Terms and Conditions for Me Vs You competitions</h1>
        <h2>
            1 ACCEPTANCE
        </h2>
        <div>
            By entering a competition, you agree you have read and accepted these terms and conditions. We reserve the
            right to verify any entries and winners and may refuse to award prizes where we have reasonable grounds to
            believe that there has been a breach of these terms and conditions. You can access these terms and
            conditions at any time during the competition at www.mevsyougame.com/legal-competition. We suggest that you
            keep a copy of these terms for your own records.
        </div>
        <h2>
            2 ELIGIBILITY
        </h2>
        <div>
            To enter, you must be 13 years of age or older. Minors must have the consent of a parent or legal guardian. We
            reserve the right to deny any individual entry to our tournaments. You need an active Steam account (not VAC
            banned), Internet, own an unmodified Me Vs You game version and you need to be able to capture the game
            footage in at least 720p 30fps. You are prohibited from participation if your participation violates any
            applicable local laws, rules or regulations in your jurisdiction ("Applicable Laws"). You are responsible
            for ensuring that your participation does not violate any Applicable Laws at any time. State and local
            taxes, including VAT, associated with the receipt of any prize are the sole responsibility of the
            participant.
        </div>
        <h2>
            3 ENTRY PERIOD
        </h2>
        <div>
            The start and end of competitions are announced on Twitter (@AmpedHorizon). Entries made after the competition
            ends are invalid.
        </div>
        <h2>
            4 HOW TO PARTICIPATE
        </h2>
        <div>
            We need a video that proofs the completion or attempt of the competition. The link and video have to be
            available for at least one week to verify the entry and to ensure there's no violation of the terms.
            The video has to clearly show the whole attempt from start to the end screen. To be transparent for everyone
            you have to make a public announcement including the necessary information either via Twitter (including
            #mevsyoucompetition) or the official Amped Horizon Discord Channel (#mevsyou-competition).

        </div>
        <h2>
            5 INVALID ENTRIES
        </h2>
        <div>
            Any entry made or attempted to be made to the competition in a manner which in our reasonable opinion is
            contrary to these terms and conditions, or by its nature unfair to other participants will be invalid and
            may result in your disqualification from the competition. Examples of entries or behavior which are not
            allowed include fraudulent entries, bulk entries, automated entries, entries made on behalf of another
            person, cheating (including a person participating in the competition on your behalf), hacking, bug using,
            deception (including tampering with the challenge settings which will result in immediate disqualification
            from all future competitions), use of robots or other automated programs. We will not accept illegible or
            incomplete entries. 
        </div>
        <h2>
            6 SELECTION OF WINNERS
        </h2>
        <div>
            The winners for the competitions will be determined in accordance with the competition goal. Score-based
            competition will get announced latest one week after the competition ends.
            In case the competition is about earning an achievement: <p class="text-danger">The winner will be
                determined based on when the announcement was made on Twitter or Discord (not the actual achievement
                date)!</p>
        </div>
        <h2>7 OWNERSHIP OF YOUR ENTRY</h2>
        <div>
            You own your entry, including any copyright in your entry. By entering a competition, you agree that we may
            edit, publish and use your entry in any and all media (including print and online) for promotion and news
            purposes, both during and after the competition, without any payment to you.
        </div>
        <h2>8 ODDS</h2>
        <div>
            The odds of winning are based on the number of eligible entries received and the skill of the entrants.
        </div>
        <h2>9 PRIZES</h2>
        <div>
            The entrant will have their name displayed on the game's website if wished. There is no fair market
            value to this prize. No more than the stated number of prizes will be awarded. No substitution, transfer, or
            assignment of prize permitted, we reserve the right to substitute a prize of equal or
            greater value in the event the offered prize is unavailable.
        </div>
        <h2>10 GENERAL CONDITIONS AND RELEASE OF LIABILITY</h2>
        <div>
            We reserve the right to cancel, change or suspend a competition for any reason, including cheating,
            technology failure, game bugs, catastrophe, war or any other unforeseen or unexpected event that affects the
            integrity of the competition, whether human or mechanical.
        </div>
    </div>
</div>