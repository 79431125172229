import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalAppSettingsServiceService {
  public readonly fullCompanyname: string = "Amped Horizon e.U.";
  public readonly homepage: string = "https://www.ampedhorizon.com";
  public readonly twitterLink: string = "https://twitter.com/AmpedHorizon";
  public readonly discordLink: string = "https://discord.gg/7YNmuutBtT";
  public readonly steamLink: string = "https://store.steampowered.com/";

  public readonly steamLinkMeVsYou: string = "https://store.steampowered.com/app/1723730/Me_Vs_You/";

  constructor() { }
}
