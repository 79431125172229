import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-game-home',
  templateUrl: './game-home.component.html',
  styleUrls: ['./game-home.component.css']
})
export class GameHomeComponent implements OnInit {

  constructor(private meta: Meta) {
    this.meta.updateTag({ name:'robots', content:'noindex'},"name='robots'");
  }

  ngOnInit(): void {
  }

}
