<div class="container">
    <div class="bg-light my-4 p-4">
        <h5><a routerLink="/home" routerLinkActive="active" class="pb-5">&laquo; Back to the main page</a></h5>
        <br/>
        <h1 class="adsimple-121817297">Privacy policy</h1>
        <p>We have written this privacy policy (version 27.08.2021-121817297) in order to explain to you, in accordance
            with the
            provisions of the <a class="adsimple-121817297"
                href="https://eur-lex.europa.eu/legal-content/EN/ALL/?uri=celex%3A32016R0679&amp%3Btid=%5Badsimpletextid]"
                target="_blank" rel="noopener">General Data Protection Regulation (EU) 2016/679</a> and applicable
            national
            laws, which personal data (data for short) we as the controller – and the processors commissioned by us
            (e.g.
            providers) – process, will process in the future and what legal options you have. The terms used are to be
            considered as gender-neutral.<br />
            <strong class="adsimple-121817297">In short:</strong> We provide you with comprehensive information about
            any
            personal data we process about you.
        </p>
        <p>Privacy policies usually sound very technical and use legal terminology. However, this privacy policy is
            intended to
            describe the most important things to you as simply and transparently as possible. So long as it aids
            transparency,
            technical <strong class="adsimple-121817297">terms are explained in a reader-friendly manner, links</strong>
            to
            further information are provided and <strong class="adsimple-121817297">graphics</strong> are used. We are
            thus
            informing in clear and simple language that we only process personal data in the context of our business
            activities
            if there is a legal basis for it. This is certainly not possible with brief, unclear and legal-technical
            statements,
            as is often standard on the Internet when it comes to data protection. I hope you find the following
            explanations
            interesting and informative. Maybe you will also find some information that you have not been familiar
            with.<br />
            If you still have questions, we would like to ask you to contact the responsible body named below or in the
            imprint,
            to follow the existing links and to look at further information on third-party sites. You can of course also
            find
            our contact details in the imprint.</p>
        <h2 id="anwendungsbereich-en" class="adsimple-121817297">Scope</h2>
        <p>This privacy policy applies to all personal data processed by our company and to all personal data processed
            by
            companies commissioned by us (processors). With the term personal data, we refer to information within the
            meaning
            of Article 4 No. 1 GDPR, such as the name, email address and postal address of a person. The processing of
            personal
            data ensures that we can offer and invoice our services and products, be it online or offline. The scope of
            this
            privacy policy includes:</p>
        <ul class="adsimple-121817297">
            <li class="adsimple-121817297">all online presences (websites, online shops) that we operate</li>
            <li class="adsimple-121817297">Social media presences and email communication</li>
            <li class="adsimple-121817297">mobile apps for smartphones and other devices</li>
        </ul>
        <p>
            <strong class="adsimple-121817297">In short:</strong> This privacy policy applies to all areas in which
            personal
            data is processed in a structured manner by the company via the channels mentioned. Should we enter into
            legal
            relations with you outside of these channels, we will inform you separately if necessary.
        </p>
        <h2 id="rechtsgrundlagen-en" class="adsimple-121817297">Legal bases</h2>
        <p>In the following privacy policy, we provide you with transparent information on the legal principles and
            regulations,
            i.e. the legal bases of the General Data Protection Regulation, which enable us to process personal
            data.<br />
            Whenever EU law is concerned, we refer to REGULATION (EU) 2016/679 OF THE EUROPEAN PARLIAMENT AND OF THE
            COUNCIL of
            April 27, 2016. You can of course access the General Data Protection Regulation of the EU online at EUR-Lex,
            the
            gateway to EU law, at <a class="adsimple-121817297"
                href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&tid=121817297"
                target="_blank"
                rel="noopener">https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A32016R0679</a>.</p>
        <p>We only process your data if at least one of the following conditions applies:</p>
        <ol>
            <li class="adsimple-121817297">
                <strong class="adsimple-121817297">Consent</strong> (Article 6 Paragraph 1 lit. a GDPR): You have given
                us your
                consent to process data for a specific purpose. An example would be the storage of data you entered into
                a
                contact form.
            </li>
            <li class="adsimple-121817297">
                <strong class="adsimple-121817297">Contract</strong> (Article 6 Paragraph 1 lit. b GDPR): We process
                your data
                in order to fulfill a contract or pre-contractual obligations with you. For example, if we conclude a
                sales
                contract with you, we need personal information in advance.
            </li>
            <li class="adsimple-121817297">
                <strong class="adsimple-121817297">Legal obligation</strong> (Article 6 Paragraph 1 lit. c GDPR): If we
                are
                subject to a legal obligation, we will process your data. For example, we are legally required to keep
                invoices
                for our bookkeeping. These usually contain personal data.
            </li>
            <li class="adsimple-121817297">
                <strong class="adsimple-121817297">Legitimate interests</strong> (Article 6 Paragraph 1 lit. f GDPR): In
                the
                case of legitimate interests that do not restrict your basic rights, we reserve the right to process
                personal
                data. For example, we have to process certain data in order to be able to operate our website securely
                and
                economically. Therefore, the processing is a legitimate interest.
            </li>
        </ol>
        <p>Other conditions such as making recordings in the interest of the public, the exercise of official authority
            as well
            as the protection of vital interests do not usually occur with us. Should such a legal basis be relevant, it
            will be
            disclosed in the appropriate place.</p>
        <p>In addition to the EU regulation, national laws also apply:</p>
        <ul class="adsimple-121817297">
            <li class="adsimple-121817297">In <strong class="adsimple-121817297">Austria</strong> this is the Austrian
                Data
                Protection Act (<strong class="adsimple-121817297">Datenschutzgesetz</strong>), in short <strong
                    class="adsimple-121817297">DSG</strong>.</li>
            <li class="adsimple-121817297">In <strong class="adsimple-121817297">Germany</strong> this is the Federal
                Data
                Protection Act (<strong class="adsimple-121817297">Bundesdatenschutzgesetz</strong>), in short <strong
                    class="adsimple-121817297">BDSG</strong>.</li>
        </ul>
        <p>Should other regional or national laws apply, we will inform you about them in the following sections.</p>
        <h2 id="kontaktdaten-verantwortliche-en" class="adsimple-121817297">Contact details of the data protection
            controller
        </h2>
        <p>If you have any questions about data protection, please contact <u><a class="adsimple-121817297" href="mailto:privacy@@@@REPLACEMEIPROTECTVSSPAMBOTS@@@ampedhorizon.com">this mail-address</a></u>

            <br />
            <a routerLink="/legal-terms" routerLinkActive="active" class="pb-5">&#8250;&#8250; Legal</a>
        </p>
        <h2 id="speicherdauer-en" class="adsimple-121817297">Storage Period</h2>
        <p>It is a general criterion for us to store personal data only for as long as is absolutely necessary for the
            provision
            of our services and products. This means that we delete personal data as soon as any reason for the data
            processing
            no longer exists. In some cases, we are legally obliged to keep certain data stored even after the original
            purpose
            no longer exists, such as for accounting purposes.</p>
        <p>If you want your data to be deleted or if you want to revoke your consent to data processing, the data will
            be
            deleted as soon as possible, provided there is no obligation to continue its storage.</p>
        <p>We will inform you below about the specific duration of the respective data processing, provided we have
            further
            information.</p>
        <h2 id="rechte-dsgvo-en" class="adsimple-121817297">Rights in accordance with the General Data Protection
            Regulation
        </h2>
        <p>You are granted the following rights in accordance with the provisions of the <a class="adsimple-121817297"
                href="https://gdpr-info.eu/" target="_blank" rel="noopener">GDPR</a> (General Data Protection
            Regulation) and
            the Austrian <a class="adsimple-121817297"
                href="https://www.ris.bka.gv.at/Dokumente/Erv/ERV_1999_1_165/ERV_1999_1_165.html" target="_blank"
                rel="noopener">Data Protection Act (DSG)</a>:</p>
        <ul class="adsimple-121817297">
            <li class="adsimple-121817297">right to rectification (article 16 GDPR)</li>
            <li class="adsimple-121817297">right to erasure (“right to be forgotten“) (article 17 GDPR)</li>
            <li class="adsimple-121817297">right to restrict processing (article 18 GDPR)</li>
            <li class="adsimple-121817297">righ to notification – notification obligation regarding rectification or
                erasure of
                personal data or restriction of processing (article 19 GDPR)</li>
            <li class="adsimple-121817297">right to data portability (article 20 GDPR)</li>
            <li class="adsimple-121817297">Right to object (article 21 GDPR)</li>
            <li class="adsimple-121817297">right not to be subject to a decision based solely on automated processing –
                including profiling – (article 22 GDPR)</li>
        </ul>
        <p>If you think that the processing of your data violates the data protection law, or that your data protection
            rights
            have been infringed in any other way, you can lodge a complaint with your respective regulatory authority.
            For
            Austria this is the data protection authority, whose website you can access at <a class="adsimple-121817297"
                href="https://www.data-protection-authority.gv.at/?tid=121817297">https://www.data-protection-authority.gv.at/</a>.
        </p>
        <h2 id="oesterreich-datenschutzbehoerde-en" class="adsimple-121817297">
        </h2>
        <h2 id="datenuebertragung-drittlaender-en" class="adsimple-121817297">Data transfer to third countries</h2>
        <p>We only transfer or process data to countries outside the EU (third countries) if you consent to this
            processing, if
            this is required by law or if it is contractually necessary. In any case, we generally only do so to the
            permitted
            extent. In most cases, your consent is the most important reason for data being processed in third
            countries. When
            personal data is being processed in third countries such as the USA, where many software manufacturers offer
            their
            services and have their servers located, your personal data may be processed and stored in unexpected ways.
        </p>
        <p>We want to expressly point out, that according to the European Court of Justice, there is currently no
            adequate level
            of protection for data transfer to the USA. Data processing by US services (such as Google Analytics) may
            result in
            data processing and retention without the data having undergone anonymisation processes. Furthermore, US
            government
            authorities may be able to access individual data. The collected data may also get linked to data from other
            services of the same provider, should you have a user account with the respective provider. We try to use
            server
            locations within the EU, whenever this is offered and possible.</p>
        <p>We will provide you with more details about data transfer to third countries in the appropriate sections of
            this
            privacy policy, whenever applicable.</p>
        <h2 id="sicherheit-datenverarbeitung-en" class="adsimple-121817297">Security of data processing operations</h2>
        <p>In order to protect personal data, we have implemented both technical and organisational measures. We encrypt
            or
            pseudonymise personal data wherever this is possible. Thus, we make it as difficult as we can for third
            parties to
            extract personal information from our data.</p>
        <p>Article 25 of the GDPR refers to “data protection by technical design and by data protection-friendly
            default” which
            means that both software (e.g. forms) and hardware (e.g. access to server rooms) appropriate safeguards and
            security
            measures shall always be placed. If applicable, we will outline the specific measures below.</p>
        <h2 id="tls-verschluesselung-https-en" class="adsimple-121817297">TLS encryption with https</h2>
        <p>The terms TLS, encryption and https sound very technical, which they are indeed. We use HTTPS (Hypertext
            Transfer
            Protocol Secure) to securely transfer data on the Internet.<br />
            This means that the entire transmission of all data from your browser to our web server is secured – nobody
            can
            “listen in”.</p>
        <p>We have thus introduced an additional layer of security and meet privacy requirements through technology
            design <a class="adsimple-121817297"
                href="https://eur-lex.europa.eu/legal-content/en/TXT/HTML/?uri=CELEX:32016R0679&from=EN&tid=121817297"
                target="_blank" rel="noopener">Article 25 Section 1 GDPR</a>). With the use of TLS (Transport Layer
            Security),
            which is an encryption protocol for safe data transfer on the internet, we can ensure the protection of
            confidential
            information.<br />
            You can recognise the use of this safeguarding tool by the little lock-symbol, which is situated in your browser’s top left corner in the left of the internet address
            (e.g.
            examplepage.uk), as well as by the display of the letters https (instead of http) as a part of our web
            address.<br />
            If you want to know more about encryption, we recommend you to do a Google search for “Hypertext Transfer
            Protocol
            Secure wiki” to find good links to further information.</p>
        <h2 id="kommunikation-en" class="adsimple-121817297">Communications</h2>
        <table border="1" cellpadding="15">
            <tbody>
                <tr>
                    <td>
                        <strong class="adsimple-121817297">Communications Overview</strong>
                        <br />
                        👥 Affected parties: Anyone who communicates with us via phone, email or online form<br />
                        📓 Processed data: e. g. telephone number, name, email address or data entered in forms. You can
                        find
                        more details on this under the respective form of contact<br />
                        🤝 Purpose: handling communication with customers, business partners, etc.<br />
                        📅 Storage duration: for the duration of the business case and the legal requirements<br />
                        ⚖️ Legal basis: Article 6 (1) (a) GDPR (consent), Article 6 (1) (b) GDPR (contract), Article 6
                        (1) (f)
                        GDPR (legitimate interests)
                    </td>
                </tr>
            </tbody>
        </table>
        <p>If you contact us and communicate with us via phone, email or online form, your personal data may be
            processed.</p>
        <p>The data will be processed for handling and processing your request and for the related business transaction.
            The
            data is stored for this period of time or for as long as is legally required.</p>
        <h3 class="adsimple-121817297">Affected persons</h3>
        <p>The above-mentioned processes affect all those who seek contact with us via the communication channels we
            provide.
        </p>
        <h3 class="adsimple-121817297">Telephone</h3>
        <p>When you call us, the call data is stored in a pseudonymised form on the respective terminal device, as well
            as by
            the telecommunications provider that is being used. In addition, data such as your name and telephone number
            may be
            sent via email and stored for answering your inquiries. The data will be erased as soon as the business case
            has
            ended and the legal requirements allow for its erasure.</p>
        <h3 class="adsimple-121817297">Email</h3>
        <p>If you communicate with us via email, your data is stored on the respective terminal device (computer,
            laptop,
            smartphone, …) as well as on the email server. The data will be deleted as soon as the business case has
            ended and
            the legal requirements allow for its erasure.</p>
        <h3 class="adsimple-121817297">Online forms</h3>
        <p>If you communicate with us using an online form, your data is stored on our web server and, if necessary,
            forwarded
            to our email address. The data will be erased as soon as the business case has ended and the legal
            requirements
            allow for its erasure.</p>
        <h3 class="adsimple-121817297">Legal bases</h3>
        <p>Data processing is based on the following legal bases:</p>
        <ul class="adsimple-121817297">
            <li class="adsimple-121817297">Art. 6 para. 1 lit. a GDPR (consent): You give us your consent to store your
                data and
                to continue to use it for the purposes of the business case;</li>
            <li class="adsimple-121817297">Art. 6 para. 1 lit. b GDPR (contract): For the performance of a contract with
                you or
                a processor such as a telephone provider, or if we have to process the data for pre-contractual
                activities, such
                as preparing an offer;</li>
            <li class="adsimple-121817297">Art. 6 para. 1 lit. f GDPR (legitimate interests): We want to conduct our
                customer
                inquiries and business communication in a professional manner. Thus, certain technical facilities such
                email
                programs, Exchange servers and mobile network operators are necessary to efficiently operate our
                communications.
            </li>
        </ul>
        <h2 id="cookies-en" class="adsimple-121817297">Cookies</h2>
        <table border="1" cellpadding="15">
            <tbody>
                <tr>
                    <td>
                        <strong class="adsimple-121817297"> Cookies Overview </strong>
                        <br />
                        👥 Affected parties: visitors to the website<br />
                        🤝 Purpose: depending on the respective cookie. You can find out more details below or from the
                        software
                        manufacturer that sets the cookie.<br />
                        📓 Processed data: Depending on the cookie used. More details can be found below or from the
                        manufacturer of the software that sets the cookie.<br />
                        📅 Storage duration: can vary from hours to years, depending on the respective cookie<br />
                        ⚖️ Legal basis: Art. 6 para. 1 lit. a GDPR (consent), Art. 6 para. 1 lit. f GDPR (legitimate
                        interests)
                    </td>
                </tr>
            </tbody>
        </table>
        <h3 class="adsimple-121817297">What are cookies?</h3>
        <p>Our website uses HTTP-cookies to store user-specific data.<br />
            In the following we explain what cookies are and why they are used, so that you can better understand the
            following
            privacy policy.</p>
        <p>Whenever you surf the Internet, you are using a browser. Common browsers are for example, Chrome, Safari,
            Firefox,
            Internet Explorer and Microsoft Edge. Most websites store small text-files in your browser. These files are
            called
            cookies.</p>
        <p>It is important to note that cookies are very useful little helpers. Almost every website uses cookies. More
            precisely, these are HTTP cookies, as there are also other cookies for other uses. HTTP cookies are small
            files that
            our website stores on your computer. These cookie files are automatically placed into the cookie-folder,
            which is
            the “brain” of your browser. A cookie consists of a name and a value. Moreover, to define a cookie, one or
            multiple
            attributes must be specified.</p>
        <p>Cookies store certain user data about you, such as language or personal page settings. When you re-open our
            website
            to visit again, your browser submits these “user-related” information back to our site. Thanks to cookies,
            our
            website knows who you are and offers you the settings you are familiar to. In some browsers, each cookie has
            its own
            file, while in others, such as Firefox, all cookies are stored in one single file.</p>
        <p>There are both first-party cookies and third-party cookies. First-party cookies are created directly by our
            site,
            while third-party cookies are created by partner-websites (e.g. Google Analytics). Each cookie must be
            evaluated
            individually, as each cookie stores different data. The expiry time of a cookie also varies from a few
            minutes to a
            few years. Cookies are not software programs and do not contain viruses, trojans or other malware. Cookies
            also
            cannot access your PC’s information.</p>
        <p>This is an example of how cookie-files can look:</p>
        <p>
            <strong class="adsimple-121817297">Name:</strong> _ga<br />
            <strong class="adsimple-121817297">Value:</strong> GA1.2.1326744211.152121817297-9<br />
            <strong class="adsimple-121817297">Purpose:</strong> Differentiation between website visitors<br />
            <strong class="adsimple-121817297">Expiry date:</strong> after 2 years
        </p>
        <p>A browser should support these minimum sizes:</p>
        <ul class="adsimple-121817297">
            <li class="adsimple-121817297">At least 4096 bytes per cookie</li>
            <li class="adsimple-121817297">At least 50 cookies per domain</li>
            <li class="adsimple-121817297">At least 3000 cookies in total</li>
        </ul>
        <h3 class="adsimple-121817297">Which types of cookies are there?</h3>
        <p>The exact cookies that we use, depend on the used services, which will be outlined in the following sections
            of this
            privacy policy. Firstly, we will briefly focus on the different types of HTTP-cookies.</p>
        <p>There are 4 different types of cookies:</p>
        <p>
            <strong class="adsimple-121817297"> Essential cookies<br />
            </strong> These cookies are necessary to ensure the basic functions of a website. They are needed when a
            user for
            example puts a product into their shopping cart, then continues surfing on different websites and comes back
            later
            in order to proceed to the checkout. These cookies ensure the shopping cart does not get deleted, even if
            the user
            closes their browser window.
        </p>
        <p>
            <strong class="adsimple-121817297"> Purposive cookies<br />
            </strong> These cookies collect information about user behaviour and whether the user receives any error
            messages.
            Furthermore, these cookies record the website’s loading time as well as its behaviour in different browsers.
        </p>
        <p>
            <strong class="adsimple-121817297"> Target-orientated cookies<br />
            </strong> These cookies ensure better user-friendliness. Thus, information such as previously entered
            locations,
            fonts sizes or data in forms stay stored.
        </p>
        <p>
            <strong class="adsimple-121817297"> Advertising cookies<br />
            </strong>These cookies are also known as targeting cookies. They serve the purpose of delivering customised
            advertisements to the user. This can be very practical, but also rather annoying.
        </p>
        <p>Upon your first visit to a website you are usually asked which of these cookie-types you want to accept.
            Furthermore,
            this decision will of course also be stored in a cookie.</p>
        <p>If you want to learn more about cookies and do not mind technical documentation, we recommend <a
                class="adsimple-121817297" href="https://tools.ietf.org/html/rfc6265" target="_blank"
                rel="nofollow noopener">https://tools.ietf.org/html/rfc6265</a>, the Request for Comments of the
            Internet
            Engineering Task Force (IETF) called “HTTP State Management Mechanism”.</p>
        <h3 class="adsimple-121817297">Purpose of processing via cookies</h3>
        <p>The purpose ultimately depends on the respective cookie. You can find out more details below or from the
            software
            manufacturer that sets the cookie.</p>
        <h3 class="adsimple-121817297">Which data are processed?</h3>
        <p>Cookies are little helpers for a wide variety of tasks. Unfortunately, it is not possible to tell which data
            is
            generally stored in cookies, but in the privacy policy below we will inform you on what data is processed or
            stored.
        </p>
        <h3 class="adsimple-121817297">Storage period of cookies</h3>
        <p>The storage period depends on the respective cookie and is further specified below. Some cookies are erased
            after
            less than an hour, while others can remain on a computer for several years.</p>
        <p>You can also influence the storage duration yourself. You can manually erase all cookies at any time in your
            browser
            (also see “Right of objection” below). Furthermore, the latest instance cookies based on consent will be
            erased is
            after you withdraw your consent. The legality of storage will remain unaffected until then.</p>
        <h3 class="adsimple-121817297">Right of objection – how can I erase cookies?</h3>
        <p>You can decide for yourself how and whether you want to use cookies. Regardless of which service or website
            the
            cookies originate from, you always have the option of erasing, deactivating or only partially accepting
            cookies. You
            can for example block third-party cookies but allow all other cookies.</p>
        <p>If you want to find out which cookies have been stored in your browser, or if you want to change or erase
            cookie
            settings, you can find this option in your browser settings:</p>
        <p>
            <a class="adsimple-121817297" href="https://support.google.com/chrome/answer/95647?tid=121817297"
                target="_blank" rel="noopener"> Chrome: Clear, enable and manage cookies in Chrome </a>
        </p>
        <p>
            <a class="adsimple-121817297"
                href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac?tid=121817297" target="_blank"
                rel="noopener"> Safari: Manage cookies and website data in Safari </a>
        </p>
        <p>
            <a class="adsimple-121817297"
                href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?tid=121817297"
                target="_blank" rel="noopener"> Firefox: Clear cookies and site data in Firefox </a>
        </p>
        <p>
            <a class="adsimple-121817297"
                href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies?tid=121817297"
                target="_blank" rel="noopener"> Internet Explorer: Delete and manage cookies </a>
        </p>
        <p>
            <a class="adsimple-121817297"
                href="https://support.microsoft.com/en-gb/help/4027947/microsoft-edge-delete-cookies?tid=121817297"
                target="_blank" rel="noopener"> Microsoft Edge: Delete cookies in Microsoft Edge </a>
        </p>
        <p>If you generally do not want cookies, you can set up your browser in a way to notify you whenever a cookie is
            about
            to be set. This gives you the opportunity to manually decide to either permit or deny the placement of every
            single
            cookie. This procedure varies depending on the browser. Therefore, it might be best for you to search for
            the
            instructions in Google. If you are using Chrome, you could for example put the search term “delete cookies
            Chrome”
            or “deactivate cookies Chrome” into Google.</p>
        <h3 class="adsimple-121817297">Legal basis</h3>
        <p>The so-called “cookie directive” has existed since 2009. It states that the storage of cookies requires your
            <strong class="adsimple-121817297">consent</strong> (Article 6 Paragraph 1 lit. a GDPR). Within countries of
            the EU,
            however, the reactions to these guidelines still vary greatly. In Austria, however, this directive was
            implemented
            in Section 96 (3) of the Telecommunications Act (TKG). In Germany, the cookie guidelines have not been
            implemented
            as national law. Instead, this guideline was largely implemented in Section 15 (3) of the Telemedia Act
            (TMG).</p>
        <p>For absolutely necessary cookies, even if no consent has been given, there are legitimate interests (Article
            6 (1)
            (f) GDPR), which in most cases are of an economic nature. We want to offer our visitors a pleasant user
            experience
            on our website. For this, certain cookies often are absolutely necessary.</p>
        <p>This is exclusively done with your consent, unless absolutely necessary cookies are used. The legal basis for
            this is
            Article 6 (1) (a) of the GDPR.</p>
        <p>In the following sections you will find more detail on the use of cookies, provided the used software does
            use
            cookies.</p>
        <h2 id="webhosting-en" class="adsimple-121817297">Web hosting</h2>
        <table border="1" cellpadding="15">
            <tbody>
                <tr>
                    <td>
                        <strong class="adsimple-121817297">Web hosting Overview</strong>
                        <br />
                        👥 Affected parties: visitors to the website<br />
                        🤝 Purpose: professional hosting of the website and security of operations<br />
                        📓 Processed data: IP address, time of website visit, browser used and other data. You can find
                        more
                        details on this below or at the respective web hosting provider.<br />
                        📅 Storage period: dependent on the respective provider, but usually 2 weeks<br />
                        ⚖️ Legal basis: Art. 6 para. 1 lit. f GDPR (legitimate interests)
                    </td>
                </tr>
            </tbody>
        </table>
        <h3 class="adsimple-121817297">What is web hosting?</h3>
        <p>Every time you visit a website nowadays, certain information – including personal data – is automatically
            created and
            stored, including on this website. This data should be processed as sparingly as possible, and only with
            good
            reason. By website, we mean the entirety of all websites on your domain, i.e. everything from the homepage
            to the
            very last subpage (like this one here). By domain we mean example.uk or examplepage.com.</p>
        <p>When you want to view a website on a screen, you use a program called a web browser. You probably know the
            names of
            some web browsers: Google Chrome, Microsoft Edge, Mozilla Firefox, and Apple Safari.</p>
        <p>The web browser has to connect to another computer which stores the website’s code: the web server. Operating
            a web
            server is complicated and time-consuming, which is why this is usually done by professional providers. They
            offer
            web hosting and thus ensure the reliable and flawless storage of website data.</p>
        <p>Whenever the browser on your computer establishes a connection (desktop, laptop, smartphone) and whenever
            data is
            being transferred to and from the web server, personal data may be processed. After all, your computer
            stores data,
            and the web server also has to retain the data for a period of time in order to ensure it can operate
            properly.</p>
        <h3 class="adsimple-121817297">Why do we process personal data?</h3>
        <p>The purposes of data processing are:</p>
        <ol>
            <li class="adsimple-121817297">Professional hosting of the website and operational security</li>
            <li class="adsimple-121817297">To maintain the operational as well as IT security</li>
            <li class="adsimple-121817297">Anonymous evaluation of access patterns to improve our offer, and if
                necessary, for
                prosecution or the pursuit of claims.li></li>
        </ol>
        <h3 class="adsimple-121817297">Which data are processed?</h3>
        <p>Even while you are visiting our website, our web server, that is the computer on which this website is saved,
            usually
            automatically saves data such as</p>
        <ul class="adsimple-121817297">
            <li class="adsimple-121817297">the full address (URL) of the accessed website (e. g.
                https://www.examplepage.uk/examplesubpage.html?tid=121817297)</li>
            <li class="adsimple-121817297">browser and browser version (e.g. Chrome 87)</li>
            <li class="adsimple-121817297">the operating system used (e.g. Windows 10)</li>
            <li class="adsimple-121817297">the address (URL) of the previously visited page (referrer URL) (e. g.
                https://www.examplepage.uk/icamefromhere.html/)</li>
            <li class="adsimple-121817297">the host name and the IP address of the device from the website is being
                accessed
                from (e.g. COMPUTERNAME and 194.23.43.121)</li>
            <li class="adsimple-121817297">date and time</li>
            <li class="adsimple-121817297">in so-called web server log files</li>
        </ul>
        <h3 class="adsimple-121817297">How long is the data stored?</h3>
        <p>Generally, the data mentioned above are stored for two weeks and are then automatically deleted. We do not
            pass these
            data on to others, but we cannot rule out the possibility that this data may be viewed by the authorities in
            the
            event of illegal conduct.</p>
        <p>
            <strong class="adsimple-121817297">In short:</strong> Your visit is logged by our provider (company that
            runs our
            website on special computers (servers)), but we do not pass on your data without your consent!
        </p>
        <h3 class="adsimple-121817297">Legal basis</h3>
        <p>The lawfulness of processing personal data in the context of web hosting is justified in Art. 6 para. 1 lit.
            f GDPR
            (safeguarding of legitimate interests), as the use of professional hosting with a provider is necessary to
            present
            the company in a safe and user-friendly manner on the internet, as well as to have the ability to track any
            attacks
            and claims, if necessary.</p>
        <h2 id="google-analytics-datenschutzerklaerung-en" class="adsimple-121817297">Google Analytics Privacy Policy
        </h2>
        <table border="1" cellpadding="15">
            <tbody>
                <tr>
                    <td>
                        <strong class="adsimple-121817297"> Google Analytics Privacy Policy Overview </strong>
                        <br />
                        👥 Affected parties: website visitors<br />
                        🤝 Purpose: Evaluation of visitor information to optimise the website.<br />
                        📓 Processed data: Access statistics that contain data such as the location of access, device
                        data,
                        access duration and time, navigation behaviour, click behaviour and IP addresses. You can find
                        more
                        details on this in the privacy policy below.<br />
                        📅 Storage period: depending on the properties used<br />
                        ⚖️ Legal basis: Art. 6 para. 1 lit. a GDPR (consent), Art. 6 para. 1 lit. f GDPR (legitimate
                        interests)
                    </td>
                </tr>
            </tbody>
        </table>
        <h3 class="adsimple-121817297">What is Google Analytics?</h3>
        <p>We use the tracking and analysis tool Google Analytics (GA) of the US-American company Google LLC (1600
            Amphitheatre
            Parkway Mountain View, CA 94043, USA). Google Analytics collects data on your actions on our website.
            Whenever you
            click a link for example, this action is saved in a cookie and transferred to Google Analytics. With the
            help of
            reports which we receive from Google Analytics, we can adapt our website and our services better to your
            wishes. In
            the following, we will explain the tracking tool in more detail, and most of all, we will inform you what
            data is
            saved and how you can prevent this.</p>
        <p>Google Analytics is a tracking tool with the purpose of conducting data traffic analysis of our website. For
            Google
            Analytics to work, there is a tracking code integrated to our website. Upon your visit to our website, this
            code
            records various actions you perform on your website. As soon as you leave our website, this data is sent to
            the
            Google Analytics server, where it is stored.</p>
        <p>Google processes this data and we then receive reports on your user behaviour. These reports can be one of
            the
            following:</p>
        <ul class="adsimple-121817297">
            <li class="adsimple-121817297">Target audience reports: With the help of target audience reports we can get
                to know
                our users better and can therefore better understand who is interested in our service.</li>
            <li class="adsimple-121817297">Advertising reports: Through advertising reports we can analyse our online
                advertising better and hence improve it.</li>
            <li class="adsimple-121817297">Acquisition reports: Acquisition reports provide us helpful information on
                how we can
                get more people enthusiastic about our service.</li>
            <li class="adsimple-121817297">Behaviour reports: With these reports, we can find out how you interact with
                our
                website. By the means of behaviour reports, we can understand what path you go on our website and what
                links you
                click.</li>
            <li class="adsimple-121817297">Conversion reports: A conversion is the process of leading you to carry out a
                desired
                action due to a marketing message. An example of this would be transforming you from a mere website
                visitor into
                a buyer or a newsletter subscriber. Hence, with the help of these reports we can see in more detail, if
                our
                marketing measures are successful with you. Our aim is to increase our conversion rate.</li>
            <li class="adsimple-121817297">Real time reports: With the help of these reports we can see in real time,
                what
                happens on our website. It makes us for example see, we can see how many users are reading this text
                right now.
            </li>
        </ul>
        <h3 class="adsimple-121817297">Why do we use Google Analytics on our website?</h3>
        <p>The objective of our website is clear: We want to offer you the best possible service. Google Analytics’
            statistics
            and data help us with reaching this goal.</p>
        <p>Statistically evaluated data give us a clear picture of the strengths and weaknesses of our website. On the
            one hand,
            we can optimise our page in a way, that makes it easier to be found by interested people on Google. On the
            other
            hand, the data helps us to get a better understanding of you as our visitor. Therefore, we can very
            accurately find
            out what we must improve on our website, in order to offer you the best possible service. The analysis of
            that data
            also enables us to carry out our advertising and marketing measures in a more individual and more
            cost-effective
            way. After all, it only makes sense to show our products and services exclusively to people who are
            interested in
            them.</p>
        <h3 class="adsimple-121817297">What data is stored by Google Analytics?</h3>
        <p>With the aid of a tracking code, Google Analytics creates a random, unique ID which is connected to your
            browser
            cookie. That way, Google Analytics recognises you as a new user. The next time you visit our site, you will
            be
            recognised as a “recurring” user. All data that is collected gets saved together with this very user ID.
            Only this
            is how it is made possible for us to evaluate and analyse pseudonymous user profiles.</p>
        <p>To analyse our website with Google Analytics, a property ID must be inserted into the tracking code. The data
            is then
            stored in the corresponding property. Google Analytics 4-property is standard for every newly created
            property. An
            alternative however, is the Universal Analytics Property. Depending on the property that is being used, data
            are
            stored for different periods of time.</p>
        <p>Your interactions on our website are measured by tags such as cookies and app instance IDs. Interactions are
            all
            kinds of actions that you perform on our website. If you are also using other Google systems (such as a
            Google
            Account), data generated by Google Analytics can be linked with third-party cookies. Google does not pass on
            any
            Google Analytics data, unless we as the website owners authorise it. In case it is required by law,
            exceptions can
            occur.</p>
        <p>The following cookies are used by Google Analytics:</p>
        <p>
            <strong class="adsimple-121817297">Name:</strong> _ga<br />
            <strong class="adsimple-121817297">Value:</strong>2.1326744211.152121817297-5<br />
            <strong class="adsimple-121817297">Purpose:</strong> By deafault, analytics.js uses the cookie _ga, to save
            the user
            ID. It generally serves the purpose of differentiating between website visitors.<br />
            <strong class="adsimple-121817297">Expiration date:</strong> After 2 years
        </p>
        <p>
            <strong class="adsimple-121817297">Name:</strong> _gid<br />
            <strong class="adsimple-121817297">Value:</strong>2.1687193234.152121817297-1<br />
            <strong class="adsimple-121817297">Purpose:</strong> This cookie also serves the purpose of differentiating
            between
            website users<br />
            <strong class="adsimple-121817297">Expiration date:</strong> After 24 hours
        </p>
        <p>
            <strong class="adsimple-121817297">Name:</strong> _gat_gtag_UA_property-id<br />
            <strong class="adsimple-121817297">Value:</strong> 1<br />
            <strong class="adsimple-121817297">Verwendungszweck:</strong> It is used for decreasing the demand rate. If
            Google Analytics is provided via Google Tag Manager, this cookie gets the name _dc_gtm_property-id.<br />
            <strong class="adsimple-121817297">Expiration date: </strong>After 1 minute
        </p>
        <p>
            <strong class="adsimple-121817297">Name:</strong> AMP_TOKEN<br />
            <strong class="adsimple-121817297">Value:</strong> No information<br />
            <strong class="adsimple-121817297">Purpose:</strong> This cookie has a token which is used to retrieve the
            user ID
            by the AMP Client ID Service. Other possible values suggest a logoff, a request or an error.<br />
            <strong class="adsimple-121817297">Expiration date:</strong> After 30 seconds up to one year
        </p>
        <p>
            <strong class="adsimple-121817297">Name:</strong> __utma<br />
            <strong class="adsimple-121817297">Value:</strong>1564498958.1564498958.1564498958.1<br />
            <strong class="adsimple-121817297">Purpose:</strong> With this cookie your behaviour on the website can be
            tracked
            and the site performance can be measured. The cookie is updated every time the information is sent to Google
            Analytics.<br />
            <strong class="adsimple-121817297">Expiration date:</strong> After 2 years
        </p>
        <p>
            <strong class="adsimple-121817297">Name:</strong> __utmt<br />
            <strong class="adsimple-121817297">Value:</strong> 1<br />
            <strong class="adsimple-121817297">Purpose:</strong> Just like _gat_gtag_UA_property-id this cookie is used
            for
            keeping the requirement rate in check.<br />
            <strong class="adsimple-121817297">Expiration date:</strong> Afer 10 minutes
        </p>
        <p>
            <strong class="adsimple-121817297">Name:</strong> __utmb<br />
            <strong class="adsimple-121817297">Value:</strong>3.10.1564498958<br />
            <strong class="adsimple-121817297">Purpose:</strong> This cookie is used to determine new sessions. It is
            updated
            every time new data or information gets sent to Google Analytics.<br />
            <strong class="adsimple-121817297">Expiration date:</strong> After 30 minutes
        </p>
        <p>
            <strong class="adsimple-121817297">Name:</strong> __utmc<br />
            <strong class="adsimple-121817297">Value:</strong> 167421564<br />
            <strong class="adsimple-121817297">Purpose:</strong> This cookie is used to determine new sessions for
            recurring
            visitors. It is therefore a session cookie, and only stays stored until you close the browser again.<br />
            <strong class="adsimple-121817297">Expiration date:</strong> After closing the browser
        </p>
        <p>
            <strong class="adsimple-121817297">Name:</strong> __utmz<br />
            <strong class="adsimple-121817297">Value:</strong> m|utmccn=(referral)|utmcmd=referral|utmcct=/<br />
            <strong class="adsimple-121817297">Purpose:</strong> This cookie is used to identify the source of the
            number of
            visitors to our website. This means, that the cookie stored information on where you came to our website
            from. This
            could be another site or an advertisement.<br />
            <strong class="adsimple-121817297">Expiration date:</strong> After 6 months
        </p>
        <p>
            <strong class="adsimple-121817297">Name:</strong> __utmv<br />
            <strong class="adsimple-121817297">Value:</strong> No information<br />
            <strong class="adsimple-121817297">Purpose:</strong> The cookie is used to store custom user data. It gets
            updated
            whenever information is sent to Google Analytics.<br />
            <strong class="adsimple-121817297">Expiration date:</strong> After 2 years
        </p>
        <p>
            <strong class="adsimple-121817297">Note:</strong> This list is by no means exhaustive, since Google are
            repeatedly
            changing the use of their cookies.
        </p>
        <p>Below we will give you an overview of the most important data that can be evaluated by Google Analytics:</p>
        <p>
            <strong class="adsimple-121817297">Heatmaps:</strong> Google creates so-called Heatmaps an. These Heatmaps
            make it
            possible to see the exact areas you click on, so we can get information on what routes you make on our
            website.
        </p>
        <p>
            <strong class="adsimple-121817297">Session duration:</strong> Google calls the time you spend on our website
            without
            leaving it session duration. Whenever you are inactive for 20 minutes, the session ends automatically.
        </p>
        <p>
            <strong class="adsimple-121817297">Bounce rate</strong> If you only look at one page of our website and then
            leave
            our website again, it is called a bounce.
        </p>
        <p>
            <strong class="adsimple-121817297">Account creation:</strong> If you create an account or make an order on
            our
            website, Google Analytics collects this data.
        </p>
        <p>
            <strong class="adsimple-121817297">IP-Address:</strong> The IP address is only shown in a shortened form, to
            make it
            impossible to clearly allocate it.
        </p>
        <p>
            <strong class="adsimple-121817297">Location:</strong> Your approximate location and the country you are in
            can be
            defined by the IP address. This process is called IP location determination.
        </p>
        <p>
            <strong class="adsimple-121817297">Technical information:</strong> Information about your browser type, your
            internet provider and your screen resolution are called technical information.
        </p>
        <p>
            <strong class="adsimple-121817297">Source:</strong> Both, Google Analytics as well as ourselves, are
            interested what
            website or what advertisement led you to our site.
        </p>
        <p>Further possibly stored data include contact data, potential reviews, playing media (e.g. when you play a
            video on
            our site), sharing of contents via social media or adding our site to your favourites. This list is not
            exhaustive
            and only serves as general guidance on Google Analytics’ data retention.</p>
        <h3 class="adsimple-121817297">How long and where is the data stored?</h3>
        <p>Google has servers across the globe. Most of them are in America and therefore your data is mainly saved on
            American
            servers. Here you can read detailed information on where Google’s data centres are located: <a
                class="adsimple-121817297" href="https://www.google.com/about/datacenters/inside/locations/?hl=en"
                target="_blank" rel="noopener">https://www.google.com/about/datacenters/inside/locations/?hl=en</a>
        </p>
        <p>Your data is allocated to various physical data mediums. This has the advantage of allowing to retrieve the
            data
            faster, and of protecting it better from manipulation. Every Google data centre has respective emergency
            programs
            for your data. Hence, in case of a hardware failure at Google or a server error due to natural disasters,
            the risk
            for a service interruption stays relatively low.</p>
        <p>The data retention period depends on the properties used. When using the newer Google Analytics 4-properties,
            the
            retention period of your user data is set to 14 months. For so-called event data, we have the option of
            choosing a
            retention period of either 2 months or 14 months.</p>
        <p>Google Analytics has a 26 months standardised period of retaining your user data. After this time, your user
            data is
            deleted. However, we have the possibility to choose the retention period of user data ourselves. There are
            the
            following five options:</p>
        <ul class="adsimple-121817297">
            <li class="adsimple-121817297">Deletion after 14 months</li>
            <li class="adsimple-121817297">Deletion after 26 months</li>
            <li class="adsimple-121817297">Deletion after 38 months</li>
            <li class="adsimple-121817297">Deletion after 50 months</li>
            <li class="adsimple-121817297">No automatical deletion</li>
        </ul>
        <p>Additionally, there is the option for data to be deleted only if you no longer visit our website within a
            period
            determined by us. In this case, the retention period will be reset every time you revisit our website within
            the
            specified period.</p>
        <p>As soon as the chosen period is expired, the data is deleted once a month. This retention period applies to
            any of
            your data which is linked to cookies, user identification and advertisement IDs (e.g. cookies of the
            DoubleClick
            domain). Any report results are based on aggregated information and are stored independently of any user
            data.
            Aggregated information is a merge of individual data into a single and bigger unit.</p>
        <h3 class="adsimple-121817297">How can I delete my data or prevent data retention?</h3>
        <p>Under the provisions of the European Union’s data protection law, you have the right to obtain information on
            your
            data and to update, delete or restrict it. With the help of a browser add on that can deactivate Google
            Analytics’
            JavaScript (ga.js, analytics.js, dc.js), you can prevent Google Analytics from using your data. You can
            download
            this add on at <a class="adsimple-121817297" href="https://tools.google.com/dlpage/gaoptout?hl=en-GB"
                target="_blank" rel="noopener">https://tools.google.com/dlpage/gaoptout?hl=en-GB</a>. Please consider
            that this
            add on can only deactivate any data collection by Google Analytics.</p>
        <p>If you generally want to deactivate, delete or manage all cookies (independently of Google Analytics), you
            can use
            one of the guides that are available for any browser:</p>
        <p>
            <a class="adsimple-121817297" href="https://support.google.com/chrome/answer/95647?tid=121817297"
                target="_blank" rel="noopener"> Chrome: Clear, enable and manage cookies in Chrome </a>
        </p>
        <p>
            <a class="adsimple-121817297"
                href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac?tid=121817297" target="_blank"
                rel="noopener"> Safari: Manage cookies and website data in Safari </a>
        </p>
        <p>
            <a class="adsimple-121817297"
                href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?tid=121817297"
                target="_blank" rel="noopener"> Firefox: Clear cookies and site data in Firefox </a>
        </p>
        <p>
            <a class="adsimple-121817297"
                href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies?tid=121817297"
                target="_blank" rel="noopener"> Internet Explorer: Delete and manage cookies </a>
        </p>
        <p>
            <a class="adsimple-121817297"
                href="https://support.microsoft.com/en-gb/help/4027947/microsoft-edge-delete-cookies?tid=121817297"
                target="_blank" rel="noopener"> Microsoft Edge: Delete cookies in Microsoft Edge </a>
        </p>
        <h3 class="adsimple-121817297">Legal basis</h3>
        <p>The use of Google Analytics requires your consent, which we obtained via our cookie popup. According to
            <strong class="adsimple-121817297"> Art. 6 para. 1 lit. a of the GDPR (consent) </strong>, this is the legal
            basis for
            the processing of personal data when collected via web analytics tools.</p>
        <p>In addition to consent, we have legitimate interest in analysing the behaviour of website visitors, in order
            to
            technically and economically improve our offer. With Google Analytics, we can recognise website errors,
            identify
            attacks and improve profitability. The legal basis for this is <strong class="adsimple-121817297"> Art. 6
                para. 1
                lit. f of the GDPR (legitimate interests) </strong>. Nevertheless, we only use Google Analytics if you
            have
            given your consent.</p>
        <p>Google also processes data in the USA, among other countries. We would like to note, that according to the
            European
            Court of Justice, there is currently no adequate level of protection for data transfers to the USA. This can
            be
            associated with various risks to the legality and security of data processing.</p>
        <p>Google uses standard contractual clauses approved by the EU Commission as basis for data processing by
            recipients
            based in third countries (outside the European Union, Iceland, Liechtenstein, Norway and especially in the
            USA) or
            data transfer there (= Art. 46, paragraphs 2 and 3 of the GDPR). These clauses oblige Google to comply with
            the EU‘s
            level of data protection when processing relevant data outside the EU. These clauses are based on an
            implementing
            order by the EU Commission. You can find the order and the clauses here: <a class="adsimple-121817297"
                href="https://ec.europa.eu/commission/presscorner/detail/en/ip_21_2847">https://ec.europa.eu/commission/presscorner/detail/en/ip_21_2847</a>
        </p>
        <p>We hope we could provide you with the most important information about data processing by Google Analytics.
            If you
            want to find out more on the tracking service, we recommend these two links: <a class="adsimple-121817297"
                href="https://marketingplatform.google.com/about/analytics/terms/gb/" target="_blank"
                rel="noopener">https://marketingplatform.google.com/about/analytics/terms/gb/</a> and <a
                class="adsimple-121817297" href="https://support.google.com/analytics/answer/6004245?hl=en"
                target="_blank" rel="noopener">https://support.google.com/analytics/answer/6004245?hl=en</a>.</p>
        <h2 id="google-analytics-ip-anonymisierung-en" class="adsimple-121817297">Google Analytics IP Anonymisation</h2>
        <p>We implemented Google Analytics’ IP address anonymisation to this website. Google developed this function, so
            this
            website can comply with the applicable privacy laws and the local data protection authorities’
            recommendations,
            should they prohibit the retention of any full IP addresses.<br />
            The anonymisation or masking of IP addresses takes place, as soon as they reach Google Analytics’ data
            collection
            network, but before the data would be saved or processed.</p>
        <p>You can find more information on IP anonymisation at <a class="adsimple-121817297"
                href="https://support.google.com/analytics/answer/2763052?hl=en" target="_blank"
                rel="noopener">https://support.google.com/analytics/answer/2763052?hl=en</a>.</p>
        <h2 id="google-analytics-berichte-zu-demografischen-merkmalen-und-interessen-en" class="adsimple-121817297">
            Google
            Analytics Reports on demographic characteristics and interests</h2>
        <p>We have turned on Google Analytics’ functions for advertising reports. These reports on demographic
            characteristics
            and interests contain details about age, gender and interests. Through them we can get a better picture of
            our users
            – without being able to allocate any data to individual persons. You can learn more about advertising
            functions at
            <a class="adsimple-121817297"
                href="https://support.google.com/analytics/answer/3450482?hl=en&amp%3Butm_id=ad" target="_blank"
                rel="noopener">auf
                https://support.google.com/analytics/answer/3450482?hl=en&amp%3Butm_id=ad</a>.
        </p>
        <p>You can terminate the use of your Google Account’s activities and information in “Ads Settings” at <a
                class="adsimple-121817297"
                href="https://adssettings.google.com/authenticated">https://adssettings.google.com/authenticated</a> via
            a
            checkbox.</p>
        <h2 id="google-analytics-deaktivierungslink-en" class="adsimple-121817297">Google Analytics Deactivation Link
        </h2>
        <p>By clicking on the following <strong class="adsimple-121817297">deactivation link</strong> you can prevent
            Google
            from tracking your further visits. Caution: The deletion of cookies, the use of your browser’s
            incognito/private
            mode or the use of a different browser may lead to your data being collected again.</p>
        <p>
            If you want to opt-out of Google Analytics, please <u><button (click)="disableAnalyticsClicked()">Click Here</button></u>
        </p>
        <h2 id="google-analytics-zusatz-zur-datenverarbeitung-en" class="adsimple-121817297">Google Analytics‘ Data
            Processing
            Amendment</h2>
        <p>By accepting the amendment on data processing in Google Analytics, we entered a contract with Google
            concerning the
            use of Google Analytics.</p>
        <p>You can find out more about the amendment on data processing for Google Analytics here: <a
                class="adsimple-121817297" href="https://support.google.com/analytics/answer/3379636?hl=en&utm_id=ad"
                target="_blank" rel="noopener">https://support.google.com/analytics/answer/3379636?hl=en&utm_id=ad</a>
        </p>
        <h2 id="google-analytics-google-signale-datenschutzerklaerung-en" class="adsimple-121817297">Google Analytics
            Google
            Signals Privacy Policy</h2>
        <p>We have activated Google signals in Google Analytics. Through this, any existing Google Analytics functions
            (advertising reports, remarketing, cross-device reports and reports on interests and demographic
            characteristics)
            are updated, to result in the summary and anonymisation of your data, should you have permitted personalised
            ads in
            your Google Account.</p>
        <p>The special aspect of this is that it involves cross-device tracking. That means your data can be analysed
            across
            multiple devices. Through the activation of Google signals, data is collected and linked to the Google
            account. For
            example, it enables Google to recognise when you look at a product on a smartphone and later buy the product
            on a
            laptop. Due to activating Google signals, we can start cross-device remarketing campaigns, which would
            otherwise not
            be possible to this extent. Remarketing means, that we can show you our products and services across other
            websites
            as well.</p>
        <p>Moreover, further visitor data such as location, search history, YouTube history and data about your actions
            on our
            website are collected in Google Analytics. As a result, we receive improved advertising reports and more
            useful
            information on your interests and demographic characteristics. These include your age, the language you
            speak, where
            you live or what your gender is. Certain social criteria such as your job, your marital status or your
            income are
            also included. All these characteristics help Google Analytics to define groups of persons or target
            audiences.</p>
        <p>Those reports also help us to better assess your behaviour, as well as your wishes and interests. As a
            result, we can
            optimise and customise our products and services for you. By default, this data expires after 26 months.
            Please
            consider, that this data is only collected if you have agreed to personalised advertisement in your Google
            Account.
            The retained information is always exclusively summarised and anonymous data, and never any data on
            individual
            persons. You can manage or delete this data in your Google Account.</p>
        <h2 id="jsdelivr-com-cdn-datenschutzerklaerung-en" class="adsimple-121817297">jsdelivr.com-CDN Privacy Policy
        </h2>
        <table border="1" cellpadding="15">
            <tbody>
                <tr>
                    <td>
                        <strong class="adsimple-121817297"> jsdelivr.com-CDN Privacy Policy Overview</strong>
                        <br />
                        👥 Affected parties: website visitors<br />
                        🤝 Purpose: Service performance optimisation (to increase website loading speed)<br />
                        📓 Processed data: data such as your IP address, browser type, browser version, the accessed
                        website or
                        time and date of the page visit<br />
                        You can find more details on this in the Privacy Policy below.<br />
                        📅 Storage period: most data is stored until it is no longer needed for the performance of the
                        service<br />
                        ⚖️ Legal bases: Art. 6 para. 1 lit. a GDPR (consent), Art. 6 para. 1 lit. f GDPR (legitimate
                        interests)
                    </td>
                </tr>
            </tbody>
        </table>
        <h3 class="adsimple-121817297">What is jsdelivr.com CDN?</h3>
        <p>In order for us to be able to deliver our individual websites to you quickly and correctly on different
            devices, we
            use the open source services of <a class="adsimple-121817297" href="https://www.jsdelivr.com/"
                target="_blank" rel="follow noopener noreferrer">jsdelivr.com</a> by the Polish software company
            ProspectOne, Królewska 65A/1,
            30-081, Kraków, Poland. jsDelivr is a Content Delivery Network (CDN). This is a network of regionally
            distributed
            servers that are connected via the Internet. As a result, content, especially large files, can be delivered
            quickly
            and optimally, even with large peak loads.</p>
        <h3 class="adsimple-121817297">Why are we using jsdelivr.com CDN?</h3>
        <p>It goes without saying that we want to provide you with a comprehensive and well-functioning service on our
            website.
            This also includes our website to load swiftly. Thanks to jsdelivr.com-CDN you can load our website much
            faster. The
            implementation of jsdelivr.com-CDN is particularly helpful for users from abroad, since the page can then be
            delivered from a server nearby.</p>
        <h3 class="adsimple-121817297">Which data is processed by jsdelivr.com-CDN?</h3>
        <p>jsDelivr is designed to download JavaScript libraries hosted on npm and Github servers. Moreover, WordPress
            plugins
            can also be loaded, provided they are hosted on <a class="adsimple-121817297" href="https://wordpress.org/"
                target="_blank" rel="follow noopener noreferrer"> WordPress.org</a>. In order to provide this service,
            your
            browser may send personal data to <a class="adsimple-121817297"
                href="https://www.jsdelivr.com" target="_blank"
                rel="noopener noreferrer">
                jsdelivr.com</a>. jsDelivr can thus collect and store user data such as IP addresses, browser type,
            browser
            version, the loaded website or the time and date of the page visit. In the Privacy Policy of <a
                class="adsimple-121817297" href="https://www.jsdelivr.com/" target="_blank"
                rel="follow noopener noreferrer">
                jsdelivr.com </a> it is expressly stated that the company does not use cookies or other tracking
            services.</p>
        <h3 class="adsimple-121817297">How long and where are the data retained?</h3>
        <p>jsDelivr has its servers distributed in different countries. Thus, your data may also be stored outside the
            European
            Economic Area. jsDelivr retains personal data that is processed on our behalf for as long as is necessary
            for
            providing their services, for fulfilling legal obligations, for settling disputes and for enforcing
            agreements.</p>
        <h3 class="adsimple-121817297">Right to object</h3>
        <p>You always have the right to information, rectification and erasure of your personal data. If you have any
            questions,
            you can always contact the responsible parties at jsdelivr.com-CDN.</p>
        <p>If you want to prevent this data transmission, you can install a JavaScript blocker (for example at <a
                class="adsimple-121817297" href="https://noscript.net/" target="_blank"
                rel="noopener noreferrer">https://noscript.net/</a>). However, please note that this means that the
            website may
            then no longer offer its usual services (such as fast loading speed).</p>
        <h3 class="adsimple-121817297">Legal basis</h3>
        <p>If you have agreed to the use of jsdelivr.com-CDN, your consent is the legal basis for the corresponding data
            processing. According to <strong class="adsimple-121817297"> Art. 6 paragraph 1 lit. a GDPR (consent)
            </strong>your
            consent is the legal basis for the processing of your personal data, as may take place when collected by
            jsdelivr.com-CDN.</p>
        <p>We also have a legitimate interest in using jsdelivr.com-CDN to optimise our online service as well as to
            make it
            more secure. The corresponding legal basis for this is <strong class="adsimple-121817297"> Art. 6 para. 1
                lit. f
                GDPR (legitimate interests)</strong>. Nevertheless, we only use the tool if you have consented to it.
        </p>
        <p>You can find more information on data processing by the software service jsDelivr in their Privacy Policy at
            <a class="adsimple-121817297" href="https://www.jsdelivr.com/privacy-policy-jsdelivr-net?tid=121817297"
                target="_ blank" rel="noopener noreferrer"> https://www.jsdelivr.com/privacy-policy-jsdelivr-net</a>.
        </p>
        <h2 id="google-fonts-datenschutzerklaerung-en" class="adsimple-121817297">Google Fonts Privacy Policy</h2>
        <table border="1" cellpadding="15">
            <tbody>
                <tr>
                    <td>
                        <strong class="adsimple-121817297"> Google Fonts Privacy Policy Overview</strong>
                        <br />
                        👥 Affected parties: website visitors<br />
                        🤝 Purpose: service optimisation<br />
                        📓 Processed data: data such as IP address, CSS and font requests<br />
                        You can find more details on this in the Privacy Policy below.<br />
                        📅 Storage period: Google stores font files for one year<br />
                        ⚖️ Legal bases: Art. 6 para. 1 lit. a GDPR (consent), Art. 6 para. 1 lit. f GDPR (legitimate
                        interests)
                    </td>
                </tr>
            </tbody>
        </table>
        <h3 class="adsimple-121817297">What are Google Fonts?</h3>
        <p>On our website we use Google Fonts, by the company Google Inc. (1600 Amphitheatre Parkway Mountain View, CA
            94043,
            USA).</p>
        <p>To use Google Fonts, you must log in and set up a password. Furthermore, no cookies will be saved in your
            browser.
            The data (CSS, Fonts) will be requested via the Google domains fonts.googleapis.com and fonts.gstatic.com.
            According
            to Google, all requests for CSS and fonts are fully separated from any other Google services. If you have a
            Google
            account, you do not need to worry that your Google account details are transmitted to Google while you use
            Google
            Fonts. Google records the use of CSS (Cascading Style Sheets) as well as the utilised fonts and stores these
            data
            securely. We will have a detailed look at how exactly the data storage works.</p>
        <p>Google Fonts (previously Google Web Fonts) is a directory with over 800 fonts that Google
            provides its users free of charge.
        </p>
        <p>Many of these fonts have been published under the SIL Open Font License license, while others have been
            published
            under the Apache license. Both are free software licenses.</p>
        <h3 class="adsimple-121817297">Why do we use Google Fonts on our website?</h3>
        <p>With Google Fonts we can use different fonts on our website and do not have to upload them to our own server.
            Google
            Fonts is an important element which helps to keep the quality of our website high. All Google fonts are
            automatically optimised for the web, which saves data volume and is an advantage especially for the use of
            mobile
            terminal devices. When you use our website, the low data size provides fast loading times. Moreover, Google
            Fonts
            are secure Web Fonts. Various image synthesis systems (rendering) can lead to errors in different browsers,
            operating systems and mobile terminal devices. These errors could optically distort parts of texts or entire
            websites. Due to the fast Content Delivery Network (CDN) there are no cross-platform issues with Google
            Fonts. All
            common browsers (Google Chrome, Mozilla Firefox, Apple Safari, Opera) are supported by Google Fonts, and it
            reliably
            operates on most modern mobile operating systems, including Android 2.2+ and iOS 4.2+ (iPhone, iPad, iPod).
            We also
            use Google Fonts for presenting our entire online service as pleasantly and as uniformly as possible.</p>
        <h3 class="adsimple-121817297">Which data is stored by Google?</h3>
        <p>Whenever you visit our website, the fonts are reloaded by a Google server. Through this external cue, data
            gets
            transferred to Google’s servers. Therefore, this makes Google recognise that you (or your IP-address) is
            visiting
            our website. The Google Fonts API was developed to reduce the usage, storage and gathering of end user data
            to the
            minimum needed for the proper depiction of fonts. What is more, API stands for „Application Programming
            Interface“
            and works as a software data intermediary.</p>
        <p>Google Fonts stores CSS and font requests safely with Google, and therefore it is protected. Using its
            collected
            usage figures, Google can determine how popular the individual fonts are. Google publishes the results on
            internal
            analysis pages, such as Google Analytics. Moreover, Google also utilises data of ist own web crawler, in
            order to
            determine which websites are using Google fonts. This data is published in Google Fonts’ BigQuery database.
            Enterpreneurs and developers use Google’s webservice BigQuery to be able to inspect and move big volumes of
            data.
        </p>
        <p>One more thing that should be considered, is that every request for Google Fonts automatically transmits
            information
            such as language preferences, IP address, browser version, as well as the browser’s screen resolution and
            name to
            Google’s servers. It cannot be clearly identified if this data is saved, as Google has not directly declared
            it.</p>
        <h3 class="adsimple-121817297">How long and where is the data stored?</h3>
        <p>Google saves requests for CSS assets for one day in a tag on their servers, which are primarily located
            outside of
            the EU. This makes it possible for us to use the fonts by means of a Google stylesheet. With the help of a
            stylesheet, e.g. designs or fonts of a website can get changed swiftly and easily.</p>
        <p>Any font related data is stored with Google for one year. This is because Google’s aim is to fundamentally
            boost
            websites’ loading times. With millions of websites referring to the same fonts, they are buffered after the
            first
            visit and instantly reappear on any other websites that are visited thereafter. Sometimes Google updates
            font files
            to either reduce the data sizes, increase the language coverage or to improve the design.</p>
        <h3 class="adsimple-121817297">How can I erase my data or prevent it being stored?</h3>
        <p>The data Google stores for either a day or a year cannot be deleted easily. Upon opening the page this data
            is
            automatically transmitted to Google. In order to clear the data ahead of time, you have to contact Google’s
            support
            at <a class="adsimple-121817297"
                href="https://support.google.com/?hl=en-GB&tid=121817297">https://support.google.com/?hl=en-GB&tid=121817297</a>.
            The only way for you to prevent the retention of your data is by not visiting our website.</p>
        <p>Unlike other web fonts, Google offers us unrestricted access to all its fonts. Thus, we have a vast sea of
            font types
            at our disposal, which helps us to get the most out of our website. You can find out more answers and
            information on
            Google Fonts at <a class="adsimple-121817297"
                href="https://developers.google.com/fonts/faq?tid=121817297">https://developers.google.com/fonts/faq?tid=121817297</a>.
            While Google does address relevant elements on data protection at this link, it does not contain any
            detailed
            information on data retention.<br />
            It proofs rather difficult to receive any precise information on stored data by Google.</p>
        <h3 class="adsimple-121817297">Legal basis</h3>
        <p>If you have consented to the use of Google Fonts, your consent is the legal basis for the corresponding data
            processing. According to <strong class="adsimple-121817297"> Art. 6 Paragraph 1 lit. a GDPR (Consent)
            </strong>your
            consent is the legal basis for the processing of personal data, as can occur when it is processed by Google
            Fonts.
        </p>
        <p>We also have a legitimate interest in using Google Font to optimise our online service. The corresponding
            legal basis
            for this is <strong class="adsimple-121817297"> Art. 6 para. 1 lit. f GDPR (legitimate interests)</strong>.
            Nevertheless, we only use Google Font if you have given your consent to it.</p>
        <p>Google also processes data in the USA, among other countries. We would like to note, that according to the
            European
            Court of Justice, there is currently no adequate level of protection for data transfers to the USA. This can
            be
            associated with various risks to the legality and security of data processing.</p>
        <p>Google uses standard contractual clauses approved by the EU Commission as basis for data processing by
            recipients
            based in third countries (outside the European Union, Iceland, Liechtenstein, Norway and especially in the
            USA) or
            data transfer there (= Art. 46, paragraphs 2 and 3 of the GDPR). These clauses oblige Google to comply with
            the EU‘s
            level of data protection when processing relevant data outside the EU. These clauses are based on an
            implementing
            order by the EU Commission. You can find the order and the clauses here: <a class="adsimple-121817297"
                href="https://ec.europa.eu/commission/presscorner/detail/en/ip_21_2847">https://ec.europa.eu/commission/presscorner/detail/en/ip_21_2847</a>
        </p>
        <p>You can find more information on which data is generally retained by Google and what this data is used at <a
                class="adsimple-121817297"
                href="https://policies.google.com/privacy?hl=de&tid=121817297">https://policies.google.com/privacy?hl=en-GB</a>.
        </p>
        <h2 id="font-awesome-datenschutzerklaerung-en" class="adsimple-121817297">Font Awesome Privacy Policy</h2>
        <p>On our website we use Font Awesome by the American company Fonticons (307 S. Main St., Suite 202,
            Bentonville, AR
            72712, USA). Upon your visit to one of our websites, the Font Awesome web font, i.e. the icons, are loaded
            via the
            Font Awesome Content Delivery Network (CDN). This way texts, fonts and icons are displayed appropriately on
            every
            device. In this privacy policy we will go into more detail on data storage and data processing by this
            service.</p>
        <h3 class="adsimple-121817297">What is Font Awesome?</h3>
        <p>Icons play an increasingly important role on websites. Font Awesome is a web font specifically designed for
            web
            designers and web developers. With Font Awesome icons can for example be scaled and coloured as desired
            using the
            CSS stylesheet language. Thus, they now replace old picture icons. Font Awesome CDN is the easiest way to
            load icons
            or fonts onto your website. To do this, we only had to embed a short line of code into our website.</p>
        <h3 class="adsimple-121817297">Why do we use Font Awesome on our website?</h3>
        <p>Font Awesome enables our websites’ content to be depicted better. This eases your navigation on our website,
            and
            helps you grasp its content better. The icons can sometimes even be used to replace whole words and save
            space. This
            is particularly useful when optimising content specifically for smartphones. The icons are inserted as HMTL
            code
            instead of as an image, which allows us to edit the icons with CSS exactly as we want. Simultaneously, Font
            Awesome
            also lets us improve our loading speed, as it only contains HTML elements and no icon images. All these
            advantages
            help us to make our website even clearer, faster and more refined for you.</p>
        <h3 class="adsimple-121817297">Which data are stored by Font Awesome?</h3>
        <p>The Font Awesome Content Delivery Network (CDN) is used to load icons and symbols. CDNs are networks of
            servers that
            are distributed around the world. They make it possible to quickly load files from locations in close
            proximity.
            When you open one of our pages, the respective icons will be provided by Font Awesome.</p>
        <p>For the web fonts to be loaded, your browser has to connect to the servers of Fonticons, Inc. For this, your
            IP
            address will be identified. Font Awesome also collects data on which icon files are downloaded, as well as
            when they
            are downloaded. Furthermore, technical data such as your browser version, screen resolution or the time when
            you
            accessed the page are also transmitted.</p>
        <p>These data are collected and stored for the following reasons:</p>
        <ul class="adsimple-121817297">
            <li class="adsimple-121817297">to optimise Content Delivery Networks</li>
            <li class="adsimple-121817297">to identify and fix technical errors</li>
            <li class="adsimple-121817297">to protect CDNs from misuse and attacks</li>
            <li class="adsimple-121817297">to calculate fees from Font Awesome Pro customers</li>
            <li class="adsimple-121817297">to identify the popularity of icons</li>
            <li class="adsimple-121817297">to establish which computer and software you are using</li>
        </ul>
        <p>If your browser does not allow web fonts, one of your PC’s standard fonts will be used automatically.
            Moreover, as
            far as we are currently aware, no cookies will be set. We are keeping in contact with Font Awesome’s privacy
            department and will let you know as soon as we find out more.</p>
        <h3 class="adsimple-121817297">How long and where are the data stored?</h3>
        <p>Font Awesome stores data about the use of the Content Delivery Network also on servers in the United States
            of
            America. However, the CDN servers are located all across the world and store user data in your proximity.
            The data
            is usually only stored for a few weeks in an identifiable form. Aggregated statistics on the use of the CDNs
            may
            also be stored for longer. However, these do not include any personal data.</p>
        <h3 class="adsimple-121817297">How can I delete my data or prevent data retention?</h3>
        <p>As far as we are aware, Font Awesome does not store any personal data via Content Delivery Networks. If you
            do not
            want data about the used icons to be stored, you will unfortunately not be able to visit our website. If
            your
            browser does not allow web fonts, no data will be transmitted or saved. In this case your computer’s default
            font
            will be used.</p>
        <p>If you want to find out more about Font Awesome and their data handling, we recommend you to read their
            privacy
            policy at <a class="adsimple-121817297"
                href="https://fontawesome.com/privacy">https://fontawesome.com/privacy</a>
            along with the help page at <a class="adsimple-121817297"
                href="https://fontawesome.com/help?tid=121817297">https://fontawesome.com/help</a>.</p>
        All texts are copyrighted.<p style="margin-top:15px;">Source: Created with the <a
                href="https://www.adsimple.at/datenschutz-generator/"
                title="Datenschutz Generator by AdSimple">Datenschutz
                Generator</a> by AdSimple</p>
    </div>
</div>