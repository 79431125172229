import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { PrivacyComponent } from './legal/privacy/privacy.component';
import { TermsComponent } from './legal/terms/terms.component';

//import {NgParticlesModule} from "ng-particles";

import {NgcCookieConsentConfig, NgcCookieConsentModule} from 'ngx-cookieconsent';
import { NotfoundComponent } from './notfound/notfound.component';
import { GameHomeComponent } from './game-home/game-home.component';
import { CompetitionComponent } from './legal/competition/competition.component';

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: 'www.mevsyougame.com' // or 'www.ampedhorizon.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#f1d600'
    }
  },
  theme: 'edgeless',
  type: 'opt-in',
  content: {
    "deny": "Allow Functional",
    "href": "/legal-privacy"
  }
};

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    PrivacyComponent,
    TermsComponent,
    NotfoundComponent,
    GameHomeComponent,
    CompetitionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    //NgParticlesModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
