import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { GoogleAnalyticsServiceService } from 'src/app/google-analytics-service.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

  constructor(private googleService: GoogleAnalyticsServiceService, private meta: Meta) {
    this.meta.updateTag({ name:'robots', content:'noindex'},"name='robots'");
  }

  ngOnInit(): void {
  }

  disableAnalyticsClicked() {
    this.googleService.disableGoogleAnalytics();
    alert('Google Analytics deactivated');
  }

}
