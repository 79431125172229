<div>
    <div class="text-center cBorder pt-1">
        <img class="img-fluid img-max" src="assets/mevsyou_hp_header.png" alt="Me Vs You Early Access Logo">
    </div>
    <div class="">
        <h1 class="neonText2 pt-2 display-1" style="font-size: 1.5em;">{{countdown}}</h1>
        <!-- <h1 class="neonText pt-5">Wishlist NOW to get Release updates</h1> -->
        <!-- <div class="text-center">
            <iframe src="https://store.steampowered.com/widget/1723730/" frameborder="0" width="646" height="190"></iframe>
        </div>
         -->
        <div class="text-center pt-1 linkButton ">
            <!-- <a class="p-1 " href="{{appSettings.steamLinkMeVsYou}}" src="assets/mevsyou_hp.PNG"><i class="fa fa-steam steamIcon"></i> Get it now</a> -->
            <a class="p-1 link" href="{{appSettings.steamLinkMeVsYou}}"><img class="link"
                    src="assets/SteamButton.png"></a>
        </div>
        <!-- <hr class=""> -->
        <div class="container pt-2">
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#players">Players</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#creators">Creators/Industry</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#press">Press</a>
                </li>
            </ul>
            <div id="myTabContent" class="tab-content">
                <div class="tab-pane fade show active" id="players">
                    <div class="card text-white bg-dark mb-3">
                        <!-- <div class="card-header">Get involved</div> -->
                        <div class="card-body">
                            <h4 class="card-title">Get Involved &#9829;</h4>
                            <p class="card-text">Early Access means we develop together with you! There's just a handful
                                of people in the Discord. Join now, your feedback is crucial!
                            </p>
                            <div class="linkButton smaller"><a href="{{appSettings.discordLink}}" target="_blank"><i
                                        class="fa fa-discord-alt"></i></a></div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="creators">
                    <div class="card text-white bg-dark mb-3">
                        <div class="card-body">
                            <h4 class="card-title">Get In Touch &#9829;</h4>
                            <p class="card-text"> Me Vs You strives to be a platform for competition. You want to host a
                                competition or you want to join forces for another project? Please <u><a
                                        class="text-white "
                                        href="mailto: office@@@@REPLACEMEIPROTECTVSSPAMBOTS@@@ampedhorizon.com">contact
                                        us</a></u>!</p>
                            <div class="linkButton smaller"><a href="{{appSettings.discordLink}}" target="_blank"><i
                                        class="fa fa-discord-alt"></i></a></div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="Press">
                    <div class="card text-white bg-dark mb-3">
                        <div class="card-body">
                            <h4 class="card-title">Get the Presskit &#9829;</h4>
                            <p class="card-text"><u><a class="text-white" href="https://www.indiedb.com/games/me-vs-you/presskit" target="_blank">IndieDB Presskit</a></u><br>

                                Please <u><a class="text-white "
                                        href="mailto: press@@@@REPLACEMEIPROTECTVSSPAMBOTS@@@ampedhorizon.com">get in
                                        touch</a></u> for any questions!</p>
                            <div class="linkButton smaller"><a href="{{appSettings.discordLink}}" target="_blank"><i
                                        class="fa fa-discord-alt"></i></a></div>

                        </div>
                    </div>

                </div>
            </div>
            <hr class="">

            <div class="container mt-3">
                <div class="row">
                    <div class="col">
                        <div class="card text-white bg-primary">
                            <div class="card-header text-center font-weight-bold text-uppercase">Competition</div>
                            <div class="card-body">
                                <div class="d-flex w-100 justify-content-between">
                                    <h5 class="mb-1"></h5>
                                    <small>#1</small>
                                </div>
                                <h4 class="card-title" style="margin-top: -30px;"><img class="achievement" src="assets/NotANoob.png"> Speedrun Pro</h4>
                                <p class="card-text">
                                    The first who submits a link which proofs how the achievement was earned (beginning to endscreen) wins the competition.<br>
                                    <a routerLink="/legal-competition" routerLinkActive="active" class="text-white">&#x2192;How to claim the <span style="font-weight: bold;">World First</span></a>
                                </p>
                            </div>
                            <div class="card-footer text-muted">
                                No Winner yet
                              </div>
                        </div>
    
                    </div>
                    <div class="col">
                        <div class="card text-center text-white bg-primary">
                            <div class="card-header font-weight-bold text-uppercase">Media</div>
                            <div class="card-body">
                                <!-- <h4 class="card-title">Dark card title</h4> -->
                                <p class="card-text">This section is reserved for community created videos or playlists. Please provide us with links <i class="fa fa-youtube-play" aria-hidden="true"></i>!</p>
                            </div>
                        </div>
                    </div>
                    </div>

                </div>



            </div>




        </div>
    </div>
    <!-- <div>
    <ng-particles class="" id="particles" [options]="options" (particlesLoaded)="particlesLoaded($event)"
    (particlesInit)="particlesInit($event)"></ng-particles>
</div> -->