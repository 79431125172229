<div class="container">
    <div class="bg-light my-4 p-4">
        <h5><a routerLink="/home" routerLinkActive="active" class="pb-5">&laquo; Back to the main page</a></h5>
        <br/>
        <h1 class="adsimple-121817297">Legal Notice</h1>
        <p class="adsimple-121817297">Information in accordance with §5 of the E-Commerce Act, §14 of the
            Unternehmensgesetzbuch, §63 of the Commercial Code and disclosure requirements under §25 of the Media Act.
        </p>
        <p class="adsimple-121817297">Amped Horizon e.U.<br />Erwin Hopf, MSc<br />Karl-Renner-Straße 3,
            <br />4040
            Linz, <br />Austria
        </p>
        <p class="adsimple-121817297">
            <strong>Object of the company:</strong> IT Dienstleistungen<br />
            <strong>VAT-Number:</strong> ATU77099426<br />
            <strong>GLN:</strong> 9110030932651<br />
            <strong>GISA:</strong> 34052075<br />
            <strong>Corporate register number:</strong> 562495z<br />
            <strong>Corporate register court:</strong> Landesgericht Linz<br />
            <strong>Company location:</strong> 4040 Linz
        </p>
        <p class="adsimple-121817297">
            <strong>Email: </strong><u><a href="mailto:office@@@@REPLACEMEIPROTECTVSSPAMBOTS@@@ampedhorizon.com">ADDRESS</a></u>
        </p>
        <p class="adsimple-121817297">
            <strong>Member of:</strong> WKO<br />
            <strong>Laws re. professions:</strong> Gewerbeordnung: www.ris.bka.gv.at
        </p>
        <p class="adsimple-121817297">
            <strong>Supervisory/Trade authority:</strong> Bezirkshauptmannschaft Linz<br />
            <strong>Job title:</strong> Software Engineer<br />
            <strong>Awarding country:</strong> Austria
        </p>
        <p class="adsimple-121817297">
            <strong>Contact details of the data protection controller</strong>
            <br />If you have any question about data protection, please find the contact details of the body or person
            responsible for data protection below:<br /> E-Mail: <u><a
                href="mailto:office@@@@REPLACEMEIPROTECTVSSPAMBOTS@@@ampedhorizon.com">ADDRESS</a></u>
        </p>
        <p style="margin-top:15px;">Source: Created with the <a href="https://www.adsimple.at/impressum-generator/"
                title="Impressum Generator by AdSimple">Impressum Generator</a> by AdSimple</p>
        <h4 id="eu-streitschlichtung-en" class="adsimple-121817297">
            Dispute resolution
        </h4>
        <p>
            The European Commission provides a platform for online dispute resolution (OS):
            https://ec.europa.eu/consumers/odr.
            Please find our email address in this legal notice.
            We do not take part in online dispute resolutions at consumer arbitration boards.
        </p>
        <h4>
            Liability for Content
        </h4>
        <p>
            Liability for content the contents of our pages have been created with the utmost care. However, we can assume no liability for the correctness, completeness and topicality of the contents. As a service provider we are according to §6. 1 MDStV and § 8 ABS. 1 TDG for own contents on these pages under the general laws responsible. Service providers are not obliged to monitor the transmitted or stored foreign information or to any circumstances which point to an illegal activity. Obligations to remove or block the use of information according to the general laws remain unaffected. A liability is only possible from the date of knowledge of a concrete infringement. When it is known from suitable law breakings we will remove this content immediately.
        </p>
        <h4 id="haftung-links-webseite-en" class="adsimple-121817297">
            Liability for Links
        </h4>
        <p>
            Our offer includes links to external third party websites. We have no influence on the content of those
            websites, therefore we cannot be held liable for the content on those websites.
            Operators or administrators of linked websites are always responsible for their own content.

            The linked websites have been checked for possible violations of applicable legislation at the time of
            creating the link.
            Illegal content were not detected at that time. Continuous monitoring of the content of linked websites
            cannot be assured without reasonable indications that there has been a violation of applicable legislation.
            Illegal links will be removed immediately once we become aware of them. Please contact us in that case.
        </p>
        <h4 id="urheberrechtshinweis-en" class="adsimple-121817297">
            Copyright
        </h4>
        <p>
            All texts, graphics, videos, multimedia content, logos and pictures are protected – also by way of
            exception; copyright-protected; any use of this content is only permitted with the express permission of
            Amped Horizon e.U.. The copyright of third parties is respected where the content shared on these websites
            does not originate from the website operator. Contributions by third parties on this site are identified as
            such. However, if you notice any violations of copyright law, please inform us. Such content will be removed
            immediately.
        </p>
        
        <h5><a routerLink="/legal-privacy" routerLinkActive="active" class="pb-5">&#8250;&#8250; Privacy Policy</a></h5>

    </div>
</div>
